<template>
  <div>
    <v-card
      class="ma-2 text-center"
      elevation="0"
      v-for="liveStreamConfig in getLiveSteamConfig"
      :key="liveStreamConfig"
    >
      <iframe
        :src="liveStreamConfig.playbackUrl"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </v-card>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";

const { getLiveSteamConfig } = storeToRefs(useAppStore());
</script>
