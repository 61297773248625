<template>
  <v-bottom-navigation v-model="value" grow>
    <v-btn
      class="pa-1"
      v-for="navButton in navButtons"
      :key="navButton.text"
      :to="navButton.route"
    >
      <v-icon class="ma-0 pa-0"> {{ navButton.icon }} </v-icon>
      {{ navButton.text }}
    </v-btn>
  </v-bottom-navigation>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
</script>
<script>
import logger from "@/utilities/logger";

export default {
  data: () => ({
    value: 0,
  }),
  computed: {
    navButtons() {
      let navItems = [
        { text: "Home", icon: "mdi-home-outline", route: "/" },
        {
          text: "Uploaded By",
          icon: "mdi-folder-account-outline",
          route: "/folder",
        },
        {
          text: "People",
          icon: "mdi-account-multiple-outline",
          route: "/persons",
        },
        {
          text: "Profile",
          icon: "mdi-account-outline",
          route: "/profile",
        },
      ];

      let liveStreamObj = {
        text: "Live Stream",
        icon: "mdi-youtube",
        route: "/liveStream",
      };

      const { getIsLiveStreamEnabled } = storeToRefs(useAppStore());

      const isLiveStreamEnabled = getIsLiveStreamEnabled.value;
      console.log("isLiveStreamEnabled", isLiveStreamEnabled);

      if (isLiveStreamEnabled) {
        console.log("Live Stream configured");
        navItems.splice(2, 0, liveStreamObj);
      }

      return navItems;
    },
  },
  async updated() {
    const store = useAppStore();
    await store.checkIfLiveStreamAvailable();
  },
  async mounted() {
    const store = useAppStore();
    await store.checkIfLiveStreamAvailable();
  },
  // watch for router changes and update the value
  watch: {
    $route() {
      logger.log("route changed", this.$route);
      let routeMapping = {
        "/": 0,
        "/folder": 1,
        "/profile": 2,
      };
      this.value = routeMapping[this.$route.path];
    },
  },
};
</script>
