// Utilities
import { defineStore } from "pinia";
import { dynamoDBQuery, dynamoDBBatchGet } from "@/services/dynamodb";
import logger from "@/utilities/logger";

const TABLE_NAME = import.meta.env.VITE_APP_DDB_TABLENAME;
const CLOUDFRONT_DOMAIN = import.meta.env.VITE_APP_CLOUDFRONT_DOMAIN;

export const useAppStore = defineStore("app", {
  state: () => ({
    userEmail: null,
    photosHome: [],
    photosUploadedBy: [],
    LastEvaluatedKey: null,
    LastEvaluatedKeyForUploadedBy: null,
    newImagesAvailable: false,
    noMoreImages: false,
    noMoreImagesForuploadedBy: false,
    currentImageUploadPercentage: 0,
    currentPhotoIndexToPreview: 0,
    showFullView: false,
    usersToShowInUploadedBy: [],
    currentSelectedUserToViewInUploadedBy: "",
    personsDetected: {},
    allDetectedPersons: [],
    LastEvaluatedKeyForPersonsDetected: null,
    noMoreImagesForPersonsDetected: false,
    currentSelecterPersonsToView: {},
    noMoreImagesByPersons: false,
    LastEvaluatedKeyByPersons: null,
    photosByPerson: [],
    //fullscreen page props
    fullScreenPropsPhotosArray: [],
    fullScreenPropsgetPhotoUrlsFun: "",

    isLiveStreamEnabled: false,
    liveStreamConfig: [],
  }),
  getters: {
    getUserEmail: (state) => state.userEmail,
    getPhotosHome: (state) => state.photosHome,
    getPhotosUploadedBy: (state) => state.photosUploadedBy,
    getIsNoMoreImages: (state) => state.noMoreImages,
    getCurrentImageUploadPercentage: (state) =>
      state.currentImageUploadPercentage,
    getNewImagesAvailable: (state) => state.newImagesAvailable,
    getCurrentPhotoIndexToPreview: (state) => state.currentPhotoIndexToPreview,
    getShowFullView: (state) => state.showFullView,
    getUsersToShowInUploadedBy: (state) => state.usersToShowInUploadedBy,
    getCurrentSelectedUserToViewInUploadedBy: (state) =>
      state.currentSelectedUserToViewInUploadedBy,
    getPersonsDetected: (state) => state.personsDetected,
    getAllDetectedPersons: (state) => state.allDetectedPersons,
    getNoMoreImagesForPersonsDetected: (state) =>
      state.noMoreImagesForPersonsDetected,
    getCurrentSelecterPersonsToView: (state) =>
      state.currentSelecterPersonsToView,
    getPhotosByPerson: (state) => state.photosByPerson,
    //if currentSelecterPersonsToView is empty then return true or false
    isCurrentSelectedPersonsToViewEmpty: (state) => {
      return Object.keys(state.currentSelecterPersonsToView).length === 0;
    },
    // fullscreen props
    getFullScreenPropsPhotosArray: (state) => state.fullScreenPropsPhotosArray,
    getFullScreenPropsgetPhotoUrlsFun: (state) =>
      state.fullScreenPropsgetPhotoUrlsFun,
    getIsLiveStreamEnabled: (state) => state.isLiveStreamEnabled,
    getLiveSteamConfig: (State) => State.liveStreamConfig,
  },
  actions: {
    async getPhotoUrls() {
      if (this.noMoreImages) {
        return "empty";
      }

      //query from ddb where on index entityType with value IMAGE and sort by PK
      const ddbParams = {
        TableName: TABLE_NAME,
        IndexName: "entityType-PK-index",
        KeyConditionExpression: "#entityType = :entityType",
        ExpressionAttributeNames: {
          "#entityType": "entityType",
        },
        ExpressionAttributeValues: {
          ":entityType": "IMAGE",
        },
        ExclusiveStartKey: this.LastEvaluatedKey,
        ScanIndexForward: false,
        Limit: 100,
      };
      logger.log("ddbParams: ", ddbParams);
      const result = await dynamoDBQuery(ddbParams);

      logger.log("result: ", result);
      if (
        result.Items &&
        result.Items.length > 0 &&
        this.photosHome.length > 0
      ) {
        if (
          result.Items[0].PK === this.photosHome[this.photosHome.length - 1].PK
        ) {
          logger.log("No more photos to fetch");
          this.noMoreImages = true;
          return "empty";
        }
      }

      // append CLOUDFRONT_DOMAIN to each item key  s3Key
      result.Items.forEach((item) => {
        item.s3Key = CLOUDFRONT_DOMAIN + item.s3Key;
        item.thumbnailFileName = item.thumbnailFileName
          ? CLOUDFRONT_DOMAIN + item.thumbnailFileName
          : null;
      });

      if (this.LastEvaluatedKey == null) {
        this.photosHome = [];
        this.photosHome = result.Items;
      } else {
        this.photosHome = [...this.photosHome, ...result.Items];
      }

      if (result.LastEvaluatedKey) {
        this.LastEvaluatedKey = result.LastEvaluatedKey;
      }
      if (!result.LastEvaluatedKey) {
        this.noMoreImages = true;
        return "empty";
      }
      return "ok";
    },
    async getPhotoUrlsBasedOnUploadedBy() {
      const user = this.currentSelectedUserToViewInUploadedBy;
      logger.log(
        "getting phots for user: ",
        user,
        this.noMoreImagesForuploadedBy
      );
      if (this.noMoreImagesForuploadedBy) {
        return "empty";
      }

      //query from ddb where on index entityType with value IMAGE and sort by PK
      const ddbParams = {
        TableName: TABLE_NAME,
        IndexName: "uploadedBy-PK-index",
        KeyConditionExpression: "#uploadedBy = :uploadedBy",
        ExpressionAttributeNames: {
          "#uploadedBy": "uploadedBy",
        },
        ExpressionAttributeValues: {
          ":uploadedBy": user,
        },
        ExclusiveStartKey: this.LastEvaluatedKeyForUploadedBy,
        ScanIndexForward: false,
        Limit: 100,
      };
      logger.log("ddbParams: ", ddbParams);
      const result = await dynamoDBQuery(ddbParams);

      logger.log("result: ", result);
      if (
        result.Items &&
        result.Items.length > 0 &&
        this.photosUploadedBy.length > 0
      ) {
        if (
          result.Items[0].PK ===
          this.photosUploadedBy[this.photosUploadedBy.length - 1].PK
        ) {
          logger.log("No more photos to fetch");
          this.noMoreImages = true;
          return "empty";
        }
      }

      // append CLOUDFRONT_DOMAIN to each item key  s3Key
      result.Items.forEach((item) => {
        item.s3Key = CLOUDFRONT_DOMAIN + item.s3Key;
        item.thumbnailFileName = item.thumbnailFileName
          ? CLOUDFRONT_DOMAIN + item.thumbnailFileName
          : null;
      });

      if (this.LastEvaluatedKeyForUploadedBy == null) {
        this.photosUploadedBy = [];
        this.photosUploadedBy = result.Items;
      } else {
        this.photosUploadedBy = [...this.photosUploadedBy, ...result.Items];
      }

      if (result.LastEvaluatedKeyForUploadedBy) {
        this.LastEvaluatedKeyForUploadedBy =
          result.LastEvaluatedKeyForUploadedBy;
      }
      if (!result.LastEvaluatedKeyForUploadedBy) {
        this.noMoreImagesForuploadedBy = true;
        return "empty";
      }
      return "ok";
    },
    resetCurrentSelectedUserToViewInUploadedBy() {
      logger.log("resetCurrentSelectedUserToViewInUploadedBy");
      this.currentSelectedUserToViewInUploadedBy = "";
      this.photosUploadedBy = [];
      this.noMoreImagesForuploadedBy = false;
      this.LastEvaluatedKeyForUploadedBy = null;
    },
    async checkNewImagesAvailable() {
      if (this.newImagesAvailable) {
        return;
      }
      const ddbParams = {
        TableName: TABLE_NAME,
        IndexName: "entityType-PK-index",
        KeyConditionExpression: "#entityType = :entityType",
        ExpressionAttributeNames: {
          "#entityType": "entityType",
        },
        ExpressionAttributeValues: {
          ":entityType": "IMAGE",
        },
        // ExclusiveStartKey: this.LastEvaluatedKey,
        ScanIndexForward: false,
        Limit: 1,
      };
      logger.log("check new images available ddbParams: ", ddbParams);
      //query from ddb where on index entityType with value IMAGE and sort by PK
      const result = await dynamoDBQuery(ddbParams);
      logger.log("result: ", result);
      // if result.Items && result.Items.length > 0 && this.photosHome[0].PK === result.Items[0].PK set newImagesAvailable to true
      if (result.Items.length > 0) {
        if (this.photosHome[0].PK != result.Items[0].PK) {
          this.newImagesAvailable = true;
        }
      }
    },
    async setUsersToShowInUploadedBy() {
      const ddbParams = {
        TableName: TABLE_NAME,
        IndexName: "entityType-PK-index",
        KeyConditionExpression: "#entityType = :entityType",
        ExpressionAttributeNames: {
          "#entityType": "entityType",
        },
        ExpressionAttributeValues: {
          ":entityType": "USER",
        },
      };
      logger.log("getUsersToShowInUploadedBy ddbParams: ", ddbParams);
      //query from ddb where on index entityType with value USER
      const result = await dynamoDBQuery(ddbParams);
      logger.log("getUsersToShowInUploadedBy result: ", result);
      const { Items } = result;

      // get only SK for username and store it in array
      this.usersToShowInUploadedBy = Items.map((item) => {
        return item.SK;
      });
    },
    async setPersonsDetectedForImage() {
      //  check if key exists in personsDetected
      let photoArray = this.photosHome;
      if (this.showFullView) {
        photoArray = this.fullScreenPropsPhotosArray;
      }
      if (
        this.personsDetected[photoArray[this.currentPhotoIndexToPreview].PK]
      ) {
        return;
      }
      //  entityType = TAGGING and PK
      const ddbParams = {
        TableName: TABLE_NAME,
        KeyConditionExpression: "PK =:PK",
        ExpressionAttributeValues: {
          ":PK": photoArray[this.currentPhotoIndexToPreview].PK,
          ":entityType": "TAGGING",
        },
        // filter entityTYpe begins with TAGGING
        ExpressionAttributeNames: {
          "#entityType": "entityType",
        },
        FilterExpression: "begins_with(#entityType, :entityType)",
      };
      logger.log("setPersonsDetectedForImage ddbParams: ", ddbParams);
      //query from ddb where on index entityType with value USER
      const result = await dynamoDBQuery(ddbParams);
      logger.log("setPersonsDetectedForImage result: ", result);
      const { Items } = result;
      let persons = [];
      Items.forEach((item) => {
        const name = item.SK.split("#")[1];
        const url = CLOUDFRONT_DOMAIN + "persons/" + name + ".jpg";
        persons.push({ name, url });
      });
      // get display Name using below and batch get
      /*
      "PK": "PERSON#person1",
      "SK": "person1",
      */
      let RequestItems = {};
      const itemsToFetch = [];

      persons.forEach((person) => {
        const item = {
          PK: "PERSON#" + person.name,
          SK: person.name,
        };
        itemsToFetch.push(item);
      });

      RequestItems[TABLE_NAME] = {
        Keys: itemsToFetch,
      };

      logger.log("batchGetParams: ", { RequestItems });
      const batchGetResults = await dynamoDBBatchGet({ RequestItems });
      logger.log("batchGetResults: ", JSON.stringify(batchGetResults));
      //   get displayName from batGetResults and update persons
      batchGetResults.Responses[TABLE_NAME].forEach((item) => {
        persons.forEach((person) => {
          if (person.name === item.SK) {
            person.displayName = item.displayName;
          }
        });
      });
      logger.log("persons: ", persons);

      this.personsDetected[photoArray[this.currentPhotoIndexToPreview].PK] =
        persons;
    },
    async setAllDetectedPersons() {
      if (
        this.noMoreImagesForPersonsDetected &&
        this.allDetectedPersons.length > 0
      ) {
        return "empty";
      }
      const ddbParams = {
        TableName: TABLE_NAME,
        IndexName: "entityType-PK-index",
        KeyConditionExpression: "#entityType = :entityType",
        ExpressionAttributeNames: {
          "#entityType": "entityType",
        },
        ExpressionAttributeValues: {
          ":entityType": "PERSON",
        },
        ScanIndexForward: false,
        Limit: 1000,
        ExclusiveStartKey: this.LastEvaluatedKeyForPersonsDetected,
      };
      logger.log("getAllDetectedPersons ddbParams: ", ddbParams);
      //query from ddb where on index entityType with value USER
      const result = await dynamoDBQuery(ddbParams);
      logger.log("getAllDetectedPersons result: ", result);
      const { Items } = result;
      let persons = [];
      Items.forEach((item) => {
        const name = item.SK;
        const displayName = item.displayName;
        const url = CLOUDFRONT_DOMAIN + "persons/" + name + ".jpg";
        persons.push({ name, url, displayName });
      });
      console.log("persons: ", persons);
      if (result.LastEvaluatedKey) {
        this.LastEvaluatedKeyForPersonsDetected = result.LastEvaluatedKey;
      }

      if (this.LastEvaluatedKeyForPersonsDetected == null) {
        this.allDetectedPersons = [];
        this.allDetectedPersons = persons;
      } else {
        this.allDetectedPersons = [...this.allDetectedPersons, ...persons];
      }

      if (!result.LastEvaluatedKey) {
        this.noMoreImagesForPersonsDetected = true;
        return "empty";
      }
    },
    async getPhotoUrlsBasedOnPerson() {
      // entityType = TAGGING and SK = currentSelecterPersonsToView.PK
      console.log(
        "currentSelectedPersonsToView: ",
        this.currentSelecterPersonsToView
      );
      if (this.noMoreImagesByPersons) {
        return "empty";
      }
      const ddbParams = {
        TableName: TABLE_NAME,
        IndexName: "entityType-PK-index",
        KeyConditionExpression: "#entityType = :entityType",
        ExpressionAttributeNames: {
          "#entityType": "entityType",
        },
        ExpressionAttributeValues: {
          ":entityType": "TAGGING#" + this.currentSelecterPersonsToView.name,
        },
        LastEvaluatedKey: this.LastEvaluatedKeyByPersons,
        ScanIndexForward: false,
        Limit: 100,
      };
      logger.log("getPhotoUrlsBasedOnPerson ddbParams: ", ddbParams);

      const result = await dynamoDBQuery(ddbParams);

      logger.log("result: ", result);
      if (
        result.Items &&
        result.Items.length > 0 &&
        this.photosByPerson.length > 0
      ) {
        if (
          result.Items[0].PK ===
          this.photosByPerson[this.photosByPerson.length - 1].PK
        ) {
          logger.log("No more photos to fetch");
          this.noMoreImagesByPersons = true;
          return "empty";
        }
      }

      // append CLOUDFRONT_DOMAIN to each item key  s3Key
      result.Items.forEach((item) => {
        item.s3Key = CLOUDFRONT_DOMAIN + item.s3Key;
        item.thumbnailFileName = item.thumbnailFileName
          ? CLOUDFRONT_DOMAIN + item.thumbnailFileName
          : null;
      });

      if (this.LastEvaluatedKeyByPersons == null) {
        this.photosByPerson = [];
        this.photosByPerson = result.Items;
      } else {
        this.photosByPerson = [...this.photosByPerson, ...result.Items];
      }

      if (result.LastEvaluatedKeyByPersons) {
        this.LastEvaluatedKeyByPersons = result.LastEvaluatedKeyByPersons;
      }
      if (!result.LastEvaluatedKeyByPersons) {
        this.noMoreImagesForuploadedBy = true;
        return "empty";
      }
      return "ok";
    },
    async checkIfLiveStreamAvailable() {
      const ddbParams = {
        TableName: TABLE_NAME,
        IndexName: "entityType-PK-index",
        KeyConditionExpression: "#entityType = :entityType",
        ExpressionAttributeNames: {
          "#entityType": "entityType",
        },
        ExpressionAttributeValues: {
          ":entityType": "LIVE_STREAM",
        },
        // ExclusiveStartKey: this.LastEvaluatedKey,
        ScanIndexForward: false,
        Limit: 1,
      };
      logger.log("check Live Stream available ddbParams: ", ddbParams);
      //query from ddb where on index entityType with value IMAGE and sort by PK
      const result = await dynamoDBQuery(ddbParams);
      logger.log("Live Stream result: ", result);
      // if result.Items && result.Items.length > 0 && this.photosHome[0].PK === result.Items[0].PK set newImagesAvailable to true
      if (result.Items.length > 0) {
        console.log("Live Stream is enabled");
        this.isLiveStreamEnabled = true;
        const stream_links = result.Items[0].stream_links;
        this.liveStreamConfig = stream_links;
      }
    },
    resetCurrentSelectedPerson() {
      this.currentSelecterPersonsToView = {};
      this.photosByPerson = [];
      this.noMoreImagesByPersons = false;
      this.LastEvaluatedKeyByPersons = null;
    },
  },
});
