<template>
  <v-banner
    color="warning"
    icon="mdi-youtube"
    lines="one"
    style="background-color: #e5e5e5"
    @click="navigate"
    v-if="showAlertBanner()"
  >
    <template v-slot:text>We Are Live Now...! </template>

    <template v-slot:actions>
      <v-btn to="/liveStream"> Watch </v-btn>
    </template>
  </v-banner>
</template>

<script setup>
import { useRoute } from "vue-router";

import moment from "moment-timezone";

import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";

const { getLiveSteamConfig } = storeToRefs(useAppStore());

const navigate = () => {
  console.log("navigate");
};

function isAnyStreamLive(streams) {
  // Get current local time
  const currentTime = moment();

  console.log("streams", streams);

  // Loop through all stream links and check if any is live
  return streams.some((stream) => {
    const startTime = moment.tz(stream.startTime, "YYYY-MM-DD HH:mm:ss Z");
    const endTime = moment.tz(stream.endTime, "YYYY-MM-DD HH:mm:ss Z");
    return currentTime.isBetween(startTime, endTime);
  });
}

const showAlertBanner = () => {
  let show = false;

  const route = useRoute();

  console.log("route: ", route.path);
  if (route.path != "/liveStream") {
    show = true;
  }
  //   //   const store = useAppStore();getLiveSteamConfig
  //   const liveStreamConfig = getLiveSteamConfig.value;

  //   console.log("liveStreamConfig: ", liveStreamConfig);

  //   if (Object.keys(liveStreamConfig).length != 0) {
  //     const isLive = isAnyStreamLive(liveStreamConfig);
  //     console.log("isLive: ", isLive);
  //   }

  return show;
};
</script>
