// Composables
import { createRouter, createWebHistory } from "vue-router";
import { Auth } from "aws-amplify";
import { useAppStore } from "@/store/app";
import logger from "@/utilities/logger";

import HomePage from "@/views/HomePage.vue";
import FolderView from "@/views/FolderView.vue";
import UserProfile from "@/views/UserProfile.vue";
import PersonsView from "@/views/PersonsView.vue";
import LiveStream from "@/views/LiveStream.vue";
// import FullscreenPreview from "@/components/FullscreenPreview.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/folder",
    component: FolderView,
    meta: { requiresAuth: true },
  },
  {
    path: "/liveStream",
    component: LiveStream,
    meta: { requiresAuth: true },
  },
  {
    path: "/persons",
    component: PersonsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/fullscreen",
  //   component: FullscreenPreview,
  //   props: true,
  //   meta: { requiresAuth: true },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * Authentication Guard for routes with requiresAuth metadata
 *
 * @param {Object} to - Intended route navigation
 * @param {Object} from - Previous route navigation
 * @param {Object} next - Next route navigation
 * @returns {Object} next - Next route
 */

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const store = useAppStore();
        store.$state.userEmail = user.signInUserSession.idToken.payload.email;
        logger.log("Logged in as: ", store.$state.userEmail);
        next();
      })
      .catch((err) => {
        logger.error("Auth.currentAuthenticatedUser(): ", err);
        Auth.federatedSignIn();
      });
  }
  next();
});

export default router;
